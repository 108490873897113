import { guid } from "core/types/Guid";
import { StorageProperties } from "core/types/storage.constants";
import { AppointmentTypeDto, BookingStatus } from "modules/booking/models";
import {
  AppointmentTypeCategoryDto,
  AppointmentTypeCategoryMappingDto,
  AppointmentTypeCategoryRequestDto
} from "modules/booking/models/appointmentTypeCategory";
import { useMemo } from "react";

import { ExpansionState, ListItem } from "./categories/types";

export const isCategory = (i: ListItem): i is AppointmentTypeCategoryDto =>
  (i as AppointmentTypeCategoryDto).appointmentTypeCategoryId !== undefined;

export const isAppointmentType = (i: ListItem): i is AppointmentTypeDto =>
  (i as AppointmentTypeDto).id !== undefined;

export const compareListItem = (left: ListItem, right: ListItem) => {
  if (
    isAppointmentType(left) &&
    !isAvailableOnline(left) &&
    isAppointmentType(right) &&
    !isAvailableOnline(right)
  ) {
    if (left.callClinicEnabled && !right.callClinicEnabled) return -1;
    if (!left.callClinicEnabled && right.callClinicEnabled) return 1;

    return left.name.localeCompare(right.name);
  }

  if (isAppointmentType(left) && !isAvailableOnline(left)) return 1;
  if (isAppointmentType(right) && !isAvailableOnline(right)) return -1;

  if (
    typeof left.sortOrder === "number" &&
    typeof right.sortOrder === "number"
  ) {
    const sortOrderResult = left.sortOrder - right.sortOrder;

    if (sortOrderResult !== 0) return sortOrderResult;
  }

  if (typeof left.sortOrder === "number") return -1;
  if (typeof right.sortOrder === "number") return 1;

  return left.name.localeCompare(right.name);
};

export const isAvailableOnline = (t: AppointmentTypeDto) =>
  t.isAvailableNewPatients || t.isAvailableExistingPatients;

export const getBookingStatus = (at: AppointmentTypeDto) => {
  if (isAvailableOnline(at)) {
    return BookingStatus.Online;
  } else if (!!at.callClinicEnabled) return BookingStatus.CallClinic;

  return BookingStatus.Offline;
};

export const useSortedListItems = (listItems: ListItem[]) => {
  return useMemo(() => [...listItems].sort(compareListItem), [listItems]);
};

export const getInitialExpansionState = (
  categories: AppointmentTypeCategoryDto[],
  userId: guid
): ExpansionState => {
  const locallyStoredExpansionState = getStorageCategoryExpansion(userId);

  return Object.fromEntries(
    categories.map(category => {
      const isStoredExpanded =
        locallyStoredExpansionState &&
        locallyStoredExpansionState[category.appointmentTypeCategoryId];

      const isExpanded =
        isStoredExpanded !== undefined ? isStoredExpanded : true;
      return [category.appointmentTypeCategoryId, isExpanded];
    })
  );
};

export const updateStorageCategoryExpansion = (
  userId: guid,
  categoryExpansion: ExpansionState
) => {
  const storageKey = `${StorageProperties.categoryExpansionState}${userId}`;
  localStorage.setItem(storageKey, JSON.stringify(categoryExpansion));
};

export const getStorageCategoryExpansion = (userId: guid) => {
  const storageKey = `${StorageProperties.categoryExpansionState}${userId}`;
  const categoryExpansionState: ExpansionState = JSON.parse(
    localStorage.getItem(storageKey) || "{}"
  );

  return categoryExpansionState;
};

export const getNewSortOrder = (
  data?: AppointmentTypeCategoryMappingDto,
  oldSortOrder?: number
) => {
  if (!data || oldSortOrder) return oldSortOrder;
  return getMaxSortOrder(data) + 1;
};

const getMaxSortOrder = (
  categoryMapping: AppointmentTypeCategoryMappingDto
) => {
  const { appointmentTypes, categories } = categoryMapping;

  const sortOrders = getSortOrders(appointmentTypes);
  categories.forEach(cat => {
    sortOrders.push(...getSortOrders(cat.appointmentTypes));
  });

  return Math.max(...sortOrders);
};

const getSortOrders = (appointmentTypes: AppointmentTypeDto[]) => {
  return appointmentTypes.map(at => {
    const { sortOrder = 0 } = at;
    return !!isAvailableOnline(at) ? sortOrder : 0;
  });
};

type RequestType = "Add" | "Remove";
export const getRequest = (
  input: {
    category: AppointmentTypeCategoryDto;
    apptTypeId: string;
    newSortOrder?: number;
  },
  requestType: RequestType
): AppointmentTypeCategoryRequestDto => {
  const { category, apptTypeId, newSortOrder } = input;
  const newApptTypeIds = getAppointmentTypeIds(
    category.appointmentTypes,
    apptTypeId,
    requestType
  );
  return {
    name: category.name,
    sortOrder: newSortOrder ?? category.sortOrder,
    appointmentTypeIds: newApptTypeIds
  };
};

const getAppointmentTypeIds = (
  data: AppointmentTypeDto[],
  apptTypeId: guid,
  requestType: RequestType
) => {
  const apptTypeIds = data.map(at => at.id);
  switch (requestType) {
    case "Add":
      return [...apptTypeIds, apptTypeId];
    case "Remove":
      return [...apptTypeIds.filter(id => id !== apptTypeId)];
    default:
      return apptTypeIds;
  }
};
